.swipe-video {
    .video_mask_overlay{
        background-color: transparent;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 2;
    }
    .content-video {
        position: relative;
        padding-bottom: 56.25%;
        position: relative;
    }
    .video {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        z-index: 1;
    }
}