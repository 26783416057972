.swipe-image {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
    opacity: 0.9;
    position: relative;
    top: 0;
    left: 0;
    z-index: 1;
}

img {
    width: 100%;
    height: auto;
}
